import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import WorkplaceCard from "../Components/WorkplaceCard";
import { Container } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { de } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { MDBRow, MDBCol, MDBTypography, MDBSpinner } from "mdb-react-ui-kit";
import Form from "react-bootstrap/Form";

registerLocale("de", de);

function Reserve() {
  interface Workplace {
    workplaceid: "";
    description: "";
    begindate: Date;
    enddate: Date;
    status: Boolean;
    DeviceWorkplace: [
      {
        deviceworkplacePK: {
          fkDeviceid: "";
          fkWorkplaceid: "";
        };
        assignmentdate: Date;
        serialnumber: "";
      }
    ];
    Room: {
      roomid: "";
      name: "";
      floor: "";
      begindate: Date;
      enddate: Date;
      status: Boolean;
    };
    Booking: [
      {
        id: "";
        bookingdate: Date;
        begindate: Date;
        enddate: Date;
        Employee: {
          employeeid: "";
          firstname: "";
          lastname: "";
          email: "";
          telephonenumber: "";
          begindate: Date;
          enddate: Date;
          status: Boolean;
        };
      }
    ];
  }
  interface buildingObj {
    buildingid: "";
    name: "";
    street: "";
    housenumber: "";
    postalcode: "";
    country: "";
    begindate: Date;
    enddate: Date;
    status: Boolean;
    city: "";
    roomList: [
      {
        roomid: "";
        name: "";
        floor: "";
        begindate: Date;
        enddate: Date;
        status: Boolean;
        workplaceList: [
          {
            workplaceid: "";
            description: "";
            begindate: Date;
            enddate: Date;
            status: Boolean;
          }
        ];
      }
    ];
  }

  const [fromDate, setFromDate] = useState(
    calculateInitialDateTime(new Date())
  );
  const [untilDate, setUntilDate] = useState(
    calculateInitialDateTime(new Date(new Date().getTime() + 60 * 60000))
  );
  function calculateInitialDateTime(date: Date) {
    if (date.getMinutes() > 45) {
      if (date.getHours() > 18) {
        date.setDate(date.getDate() + 1);
        date.setHours(5);
        date.setMinutes(0);
        date.setMilliseconds(0);
      } else {
        date.setHours(date.getHours() + 1);
        date.setMinutes(0);
        date.setMilliseconds(0);
      }
    } else if (date.getMinutes() > 30) {
      date.setMinutes(45);
      date.setMilliseconds(0);
    } else if (date.getMinutes() > 15) {
      date.setMinutes(30);
      date.setMilliseconds(0);
    } else {
      date.setMinutes(15);
      date.setMilliseconds(0);
    }
    return date;
  }

  const [selectedBuilding, setSelectedBuilding] = useState<buildingObj>();
  const [selectedRoom, setSelectedRoom] = useState("");
  const [buildings, setBuildings] = useState<buildingObj[]>([]);
  const [workplaces, setWorkplaces] = useState<Workplace[]>([]);
  const [filteredWorkplaces, setFilteredWorkplaces] = useState<Workplace[]>([]);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userId") == null) {
      return navigate("/signin", { replace: true });
    } else {
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/workplaces")
        .then((response) => response.json())
        .then((data) => {
          setWorkplaces(data);
          setFilteredWorkplaces(
            filterWorkplaceByRoom(filterWorkplaceByDate(data))
          );
        })
        .catch((err) => {
          console.log(err.message);
        });
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/buildings")
        .then((response) => response.json())
        .then((data) => {
          setBuildings(data);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const [excludedTimes, setExcludedTimes] = useState<Date[]>([]);
  useEffect(() => {
    let disabledTimes = Array();
    for (let i = 5; i <= fromDate.getHours(); i++) {
      for (let j = 0; j < 4; j++) {
        if (fromDate.getHours() !== i && fromDate.getMinutes() !== j * 15) {
          disabledTimes.push(
            new Date(
              fromDate.getFullYear(),
              fromDate.getMonth(),
              fromDate.getDate(),
              i,
              j * 15
            )
          );
        } else {
          return;
        }
      }
    }
    setExcludedTimes(disabledTimes);
  }, [fromDate]);

  useEffect(() => {
    setFilteredWorkplaces(
      filterWorkplaceByBuilding(
        filterWorkplaceByRoom(filterWorkplaceByDate(workplaces))
      )
    );
  }, [selectedRoom, untilDate, fromDate, selectedBuilding]);

  function filterWorkplaceByBuilding(
    workplacesToFilter: Workplace[]
  ): Workplace[] {
    let workplaceFilteredByBuilding = Array();
    workplacesToFilter.forEach((workplace) => {
      let containsBuilding = false;
      if (selectedBuilding) {
        selectedBuilding.roomList.forEach((room) => {
          if (workplace.Room.roomid === room.roomid) {
            containsBuilding = true;
          }
        });
      } else {
        containsBuilding = true;
      }
      if (containsBuilding) {
        workplaceFilteredByBuilding.push(workplace);
      }
    });
    return workplaceFilteredByBuilding;
  }
  function filterWorkplaceByRoom(workplacesToFilter: Workplace[]): Workplace[] {
    let workplaceFilteredByRoom = Array();
    workplacesToFilter.forEach((workplace) => {
      if (workplace.Room.roomid === selectedRoom || !selectedRoom) {
        workplaceFilteredByRoom.push(workplace);
      }
    });
    return workplaceFilteredByRoom;
  }

  function filterWorkplaceByDate(workplacesToFilter: Workplace[]): Workplace[] {
    let workplaceFilteredByDate = Array();
    workplacesToFilter.forEach((workplace) => {
      let available = true;
      workplace.Booking.forEach((booking: any) => {
        if (
          fromDate.getTime() < booking.enddate &&
          untilDate.getTime() > booking.begindate
        ) {
          available = false;
          return;
        }
      });
      if (available) {
        workplaceFilteredByDate.push(workplace);
      }
    });
    return workplaceFilteredByDate;
  }

  function handleChange(e: any) {
    e.target.value
      ? setSelectedBuilding(JSON.parse(e.target.value))
      : setSelectedBuilding(undefined);

    setSelectedRoom("");
  }

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <Container>
        <MDBRow className="row-cols-2 row-cols-md-2 row-cols-lg-4 reserveFilter">
          <MDBCol className="text-center">
            <MDBTypography
              variant="h3"
              className="mt-5 mb-0"
              style={{ color: "#003C64" }}
            >
              Von Zeitraum
            </MDBTypography>
            <DatePicker
              className="form-control mt-4"
              showPopperArrow={false}
              selected={fromDate}
              onChange={(date: Date) => {
                setFromDate(date);
                setUntilDate(
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    date.getHours() < 20
                      ? new Date(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                          untilDate.getHours(),
                          untilDate.getMinutes(),
                          date.getMilliseconds()
                        ).getTime() -
                          date.getTime() <
                        60 * 60 * 1000
                        ? date.getHours() + 1
                        : untilDate.getHours()
                      : date.getHours(),
                    new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      untilDate.getHours(),
                      untilDate.getMinutes(),
                      date.getMilliseconds()
                    ).getTime() -
                      date.getTime() <
                    60 * 60 * 1000
                      ? date.getMinutes()
                      : untilDate.getMinutes(),
                    date.getSeconds()
                  )
                );
              }}
              minDate={new Date()}
              minTime={new Date(-1, -1, -1, 5, 0, 0)}
              maxTime={new Date(-1, -1, -1, 20, 0, 0)}
              showTimeSelect
              filterDate={(date) => {
                // Disable weekends (Saturday and Sunday)
                return date.getDay() !== 0 && date.getDay() !== 6;
              }}
              locale="de"
              timeFormat="HH:mm"
              timeCaption="Uhrzeit"
              timeIntervals={15}
              dateFormat="dd MMMM yyyy HH:mm"
              placeholderText="Von"
            />
          </MDBCol>
          <MDBCol className="text-center">
            <MDBTypography
              variant="h3"
              className="mt-5 mb-0"
              style={{ color: "#003C64" }}
            >
              Bis Zeitraum
            </MDBTypography>
            <DatePicker
              className="form-control mt-4"
              showPopperArrow={false}
              selected={untilDate}
              onChange={(date: Date) => {
                setUntilDate(date);
              }}
              filterDate={(date) => {
                // Disable weekends (Saturday and Sunday)
                return date.getDay() !== 0 && date.getDay() !== 6;
              }}
              minDate={fromDate}
              minTime={new Date(-1, -1, -1, 5, 0, 0)}
              maxTime={new Date(-1, -1, -1, 20, 0, 0)}
              showTimeSelect
              excludeTimes={excludedTimes}
              locale="de"
              timeFormat="HH:mm"
              timeCaption="Uhrzeit"
              timeIntervals={15}
              dateFormat="dd MMMM yyyy HH:mm"
              placeholderText="Bis"
            />
          </MDBCol>
          <MDBCol className="text-center">
            <MDBTypography
              variant="h3"
              className="mt-5 mb-0"
              style={{ color: "#003C64" }}
            >
              Gebäude Filter
            </MDBTypography>
            <Form.Select
              className="my-4"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option value="">Nicht gesetzt</option>
              {buildings.map((building) => (
                <option
                  key={building.buildingid}
                  value={JSON.stringify(building)}
                >
                  {building.name}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
          <MDBCol className="text-center">
            <MDBTypography
              variant="h3"
              className="mt-5 mb-0"
              style={{ color: "#003C64" }}
            >
              Raum Filter
            </MDBTypography>
            <Form.Select
              className="my-4"
              aria-label="Default select example"
              onChange={(e: any) => {
                setSelectedRoom(e.currentTarget.value);
              }}
            >
              <option value="">Nicht gesetzt</option>
              {selectedBuilding
                ? buildings
                    .filter(
                      (building) =>
                        selectedBuilding?.buildingid === building.buildingid
                    )
                    .map(({ roomList }) =>
                      roomList.map(({ roomid, name }) => (
                        <option key={roomid} value={roomid}>
                          {name}
                        </option>
                      ))
                    )
                : buildings.map(({ roomList }) =>
                    roomList.map(({ roomid, name }) => (
                      <option key={roomid} value={roomid}>
                        {name}
                      </option>
                    ))
                  )}
            </Form.Select>
          </MDBCol>
        </MDBRow>
        <MDBRow className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-1 workplacecards">
          {filteredWorkplaces.map(
            ({ workplaceid, description, DeviceWorkplace, Room, Booking }) => {
              return (
                <MDBCol key={workplaceid}>
                  <WorkplaceCard
                    workplaceid={workplaceid}
                    description={description}
                    DeviceWorkplace={DeviceWorkplace}
                    Room={Room}
                    Booking={Booking}
                    fromDate={fromDate}
                    untilDate={untilDate}
                  />
                </MDBCol>
              );
            }
          )}
        </MDBRow>
        <div className="my-5"></div>
      </Container>
    </>
  );
}
export default Reserve;
