import React from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBAccordion,
  MDBAccordionItem,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBCollapse,
  MDBTypography,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useState, useEffect } from "react";

export function WorkplaceCard({
  workplaceid,
  description,
  DeviceWorkplace,
  Room,
  Booking,
  fromDate,
  untilDate,
}: {
  workplaceid: any;
  description: any;
  DeviceWorkplace: any;
  Room: any;
  Booking: any;
  fromDate: any;
  untilDate: any;
}) {
  interface deviceObj {
    deviceid: string;
    manufacturer: string;
    begindate: Date;
    enddate: Date;
    status: Boolean;
    DeviceWorkplace: [
      {
        deviceWorkplacePK: {
          fkDeviceid: string;
          fkWorkplaceid: string;
        };
        assignmentdate: Date;
        serialnumber: string;
        workplace: {
          workplaceid: string;
          description: string;
          begindate: Date;
          enddate: Date;
          status: Boolean;
        };
      }
    ];
    DeviceType: {
      devicetypeid: string;
      description: string;
    };
  }
  const [devices, setDevices] = useState<deviceObj[]>([]);
  useEffect(() => {
    fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/devices")
      .then((response) => response.json())
      .then((data) => {
        let workplaceDeviceList = new Array();
        data.forEach((device: deviceObj) => {
          device.DeviceWorkplace.forEach((deviceWorkplace: any) => {
            if (deviceWorkplace.workplace.workplaceid === workplaceid) {
              workplaceDeviceList = [...workplaceDeviceList,device];
            }
          });
          setDevices(workplaceDeviceList);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  function createBooking() {
    let bookingAlreadyExistsByUser = false;
    let bookingAlreadyExists = false;
    let ONE_HOUR = 59 * 60 * 1000;
    if (untilDate.getTime() - fromDate.getTime() >= ONE_HOUR) {
      if (untilDate.getDate() === fromDate.getDate()) {
        fetch(
          "https://neatseat.kiebackpeter.kup:8443/neatseat/api/employee/" +
            localStorage.getItem("userId")
        )
          .then((response) => response.json())
          .then((data) => {
            data.Booking.forEach((booking: any) => {
              if (
                fromDate.getTime() < booking.enddate &&
                untilDate.getTime() > booking.begindate
              ) {
                bookingAlreadyExistsByUser = true;
              }
            });
            fetch(
              "https://neatseat.kiebackpeter.kup:8443/neatseat/api/workplace/" +
                workplaceid
            )
              .then((response) => response.json())
              .then((data) => {
                data.Booking.forEach((booking: any) => {
                  if (
                    fromDate.getTime() < booking.enddate &&
                    untilDate.getTime() > booking.begindate
                  ) {
                    bookingAlreadyExists = true;
                  }
                });
                if (
                  !(
                    fromDate.getTime() < Date.now() ||
                    untilDate.getTime() < Date.now()
                  )
                ) {
                  if (!bookingAlreadyExists && !bookingAlreadyExistsByUser) {
                    fetch(
                      "https://neatseat.kiebackpeter.kup:8443/neatseat/api/booking",
                      {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          bookingdate: Date.now(),
                          begindate: fromDate.getTime(),
                          enddate: untilDate.getTime(),
                          Employee: {
                            employeeid: localStorage.getItem("userId"),
                          },
                          Workplace: {
                            workplaceid: workplaceid,
                          },
                        }),
                      }
                    ).then((response) => {
                      if (response.ok) {
                        setConfirmationModalCode(100);
                        toggleShow();
                      } else {
                        setConfirmationModalCode(404);
                        toggleShow();
                      }
                    });
                  } else if (bookingAlreadyExistsByUser) {
                    setConfirmationModalCode(300);
                    toggleShow();
                  } else {
                    setConfirmationModalCode(301);
                    toggleShow();
                  }
                } else {
                  setConfirmationModalCode(200);
                  toggleShow();
                }
              });
          });
      } else {
        setConfirmationModalCode(203);
        toggleShow();
      }
    } else if (untilDate.getTime() - fromDate.getTime() < 0) {
      setConfirmationModalCode(202);
      toggleShow();
    } else {
      setConfirmationModalCode(201);
      toggleShow();
    }
  }

  const [showFirstElement, setShowFirstElement] = useState(false);
  const [showSecondElement, setShowSecondElement] = useState(false);

  const toggleFirstElement = () => setShowFirstElement(!showFirstElement);
  const toggleSecondElement = () => setShowSecondElement(!showSecondElement);

  const [confirmationModalCode, setConfirmationModalCode] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleShow = () => setConfirmationModal(!confirmationModal);

  function confirmationModalText(confirmationModalCode: number) {
    switch (confirmationModalCode) {
      case 100:
        return (
          "Buchung erfolgreich durchgeführt für den " +
          new Date(fromDate).toLocaleDateString([], {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          }) +
          " um " +
          new Date(fromDate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " Uhr bis zum " +
          new Date(untilDate).toLocaleDateString([], {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          }) +
          " um " +
          new Date(untilDate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " Uhr"
        );
      case 200:
        return (
          <p>Der Buchungszeitraum darf nicht in der Vergangenheit liegen.</p>
        );
      case 201:
        return (
          <p>
            Der Buchungszeitraum ist zu kurz. <br /> Eine Buchung muss
            mindestens eine Stunde lang sein.
          </p>
        );

      case 202:
        return <p>Der Von Zeitraum liegt nach dem Bis Zeitraum.</p>;
      case 203:
        return <p>Die Buchung darf nicht über mehrere Tage sein.</p>;
      case 300:
        return <p>Es liegt bereits eine Buchung für diesen Zeitraum vor.</p>;
      case 301:
        return <p>Dieser Arbeitsplatz ist inzwischen vergeben.</p>;
      case 404:
        return <p>Die Buchung konnte nicht erstellt werden.</p>;
      default:
        return <p>Es gab einen Fehler bei der Buchung.</p>;
    }
  }

  return (
    <>
      <MDBModal show={confirmationModal} setShow={setConfirmationModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {confirmationModalCode === 100 ? (
                  <MDBIcon fas className="me-2" icon="check" />
                ) : (
                  <MDBIcon fas className="me-2" icon="exclamation-triangle" />
                )}
                {description}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setConfirmationModal(!confirmationModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {confirmationModalText(confirmationModalCode)}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBCard className="text-black h-100 workplacecard mb-2">
        <MDBCardBody className="mt-2 d-flex flex-column">
          <div className="text-center">
            <MDBCardTitle
              style={{
                color: "#003C64",
              }}
            >
              {description}
            </MDBCardTitle>
            <p className="text-muted mb-4">
              {Room.name}
              <br />
              {Room.floor}. Etage
            </p>
          </div>
          <div className="mb-3">
            {devices.sort((a,b) => a.DeviceType.description.localeCompare(b.DeviceType.description)).map((device) => {
              return (
                <div
                  className="d-flex justify-content-between"
                  key={device.deviceid}
                >
                  <span className="mx-4">{device.DeviceType.description}</span>
                  <span className="mx-4">{device.manufacturer}</span>
                </div>
              );
            })}{" "}
          </div>
          <div className="text-center mt-auto mb-3">
            <MDBBtn
              id="workplacebtn"
              className="text-center rounded-3"
              outline
              rounded
              onClick={() => createBooking()}
            >
              Jetzt buchen
            </MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
}
export default WorkplaceCard;
