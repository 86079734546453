import Navbar from "../Components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBTypography, MDBSpinner } from "mdb-react-ui-kit";

function Homepage() {
  interface bookingObj {
    id: string;
    bookingdate: string;
    begindate: string;
    enddate: string;
    Workplace: {
      workplaceid: string;
      description: string;
      begindate: string;
      enddate: string;
      status: Boolean;
    };
  }
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("userId") == null) {
      return navigate("/signin", { replace: true });
    } else {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/api/employee/" +
          localStorage.getItem("userId")
      )
        .then((response) => response.json())
        .then((data) => {
          let updateBooking = Array();
          data.Booking.forEach((booking: bookingObj) => {
            if (Number(booking.begindate) > Date.now()) {
              updateBooking.push(booking);
            }
          });
          updateBooking.sort(
            (a, b) =>
              new Date(a.begindate).getTime() - new Date(b.begindate).getTime()
          );
          setBookings(updateBooking);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  const [bookings, setBookings] = useState<bookingObj[]>([]);
  const navigate = useNavigate();
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <div
        className="bg-image"
        style={{
          backgroundImage:
            "url('https://i.ibb.co/PTHYbkB/Unbena312314141nnt-2.png')",
          backgroundColor: "white",
          position: "fixed",
          width: "100vw",
          height: "100vh",
          padding: "0",
          margin: "0",
        }}
      >
        {" "}
      </div>
      <div
        className="positioning"
        style={{
          position: "absolute",
          top: "30%",
          left: "10%",
          width: "30vw",
        }}
      >
        <MDBTypography
          variant="h1"
          tag="strong"
          className="d-flex justify-content-center align-items-center text-center"
          style={{
            color: "#003C64",
            fontSize: "3rem",
          }}
        >
          <MDBTypography
            tag="strong"
            className="d-flex justify-content-center align-items-center text-center"
          >
            Es ist Zeit, das
          </MDBTypography>
        </MDBTypography>
        <MDBTypography
          variant="h1"
          className="pb-4 mb-5 border-bottom text-center"
          style={{
            color: "#F07D00",
            fontSize: "3rem",
          }}
        >
          <MDBTypography tag="strong">Homeoffice zu verlassen!</MDBTypography>
        </MDBTypography>
        <MDBTypography
          variant="h4"
          className="text-center my-5"
          style={{
            color: "#003C64",
          }}
        >
          Schön, dich wiederzusehen. <br />
          <MDBTypography tag="small" className="text-muted">
            {bookings.length > 0
              ? "Hier sind deine anstehenden Buchungen im Überblick."
              : "Du hast keine anstehenden Buchungen. Zeit dies zu ändern!"}
          </MDBTypography>
        </MDBTypography>
        {bookings
          .slice(0, 3)
          .map(({ Workplace, begindate, enddate, bookingdate }, index) => (
            <div key={index}>
              <MDBTypography
                variant="h5"
                className="text-center"
                style={{
                  color: "#003C64",
                }}
              >
                {Workplace.description}
              </MDBTypography>
              <MDBTypography className="text-center text-muted pb-4">
                Am{" "}
                {new Date(begindate).toLocaleDateString([], {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}{" "}
                von{" "}
                {new Date(begindate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                bis{" "}
                {new Date(enddate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                Uhr.
              </MDBTypography>
            </div>
          ))}
        {bookings.length > 0 ? (
          ""
        ) : (
          <MDBBtn
            color="dark"
            className="w-100 mb-4 py-2"
            size="lg"
            style={{
              backgroundColor: "#F07D00",
              borderColor: "#F07D00",
            }}
            onClick={() => navigate("/reserve")}
          >
            Lass mich eine neue Buchung machen!
          </MDBBtn>
        )}
      </div>
    </>
  );
}
export default Homepage;
