import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

function SignUp() {
  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      return navigate("/home", { replace: true });
    } else {
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/companies")
        .then((response) => response.json())
        .then((data) => {
          setCompanies(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/departments")
        .then((response) => response.json())
        .then((data) => {
          setDepartments(data);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function singupRequest() {
    fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: formValue.emailValue,
        lastname: formValue.lastnameValue,
        firstname: formValue.firstnameValue,
        telephonenumber: formValue.telephoneValue,
        department: formValue.departmentValue,
        company: formValue.companyValue,
        role: ["ROLE_USER"],
        password: formValue.passwordValue,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setLoading(true);
          navigate("/signin", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  const onChange = (e: any) => {
    console.log(e);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const onChangeRecaptcha = (e: any) => {
    setFormValue({ ...formValue, ["recaptchaValue"]: e });
  };
  const [formValue, setFormValue] = useState({
    firstnameValue: "",
    lastnameValue: "",
    emailValue: "",
    passwordValue: "",
    validationPasswordValue: "",
    telephoneValue: "",
    departmentValue: "",
    companyValue: "",
    recaptchaValue: "",
  });

  function onSubmitWithReCAPTCHA(value: any) {
    console.log("Captcha value:", value);
  }

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }
  return (
    <>
      <div
        className="bg-image"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/flagged/photo-1574512468809-d05a4fd197fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')",
          height: "100vh",
        }}
      />
      <MDBRow
        className="SignUpRow"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBCol
          md="5"
          className="text-center text-md-start d-flex flex-column justify-content-center LogoAd"
        >
          <div className="d-flex flex-row mt-2 justify-content-center">
            <img
              height="60"
              src="https://www.kieback-peter.com/typo3conf/ext/itools_site/Resources/Public/images/layout/KP_Logo_web.svg"
              alt="Kieback&Peter Logo"
            />
          </div>
          <h4
            className="my-1 display-3 fw-bold ls-tight px-4"
            style={{ color: "#003C64" }}
          >
            Arbeiten <br />
            <span className="typewriter-holder" style={{ color: "#F07D00" }}>
              von überall.
            </span>
          </h4>
        </MDBCol>

        <MDBCol md="6" className="position-relative">
          <div
            id="radius-shape-1"
            className="position-absolute rounded-circle shadow-5-strong"
          ></div>
          <div
            id="radius-shape-2"
            className="position-absolute shadow-5-strong"
          ></div>

          <MDBCard className="my-5 bg-glass signUpCard rounded-4">
            <MDBCardBody className="p-5">
              <MDBValidation
                onSubmit={(e: any) => {
                  if (e.target.checkValidity()) {
                    if(formValue.recaptchaValue){
                      singupRequest();
                    }
                  }
                }}
                noValidate
              >
                <MDBRow>
                  <div className="my-5 d-flex flex-row mt-2 justify-content-center">
                    <img
                      height="60"
                      src="https://i.ibb.co/1Z4J8yS/7448084652-506eff86-b054-48ac-8d8f-dd344f6d4e9a.png"
                      alt="Kieback&Peter Logo"
                    />
                  </div>
                  <MDBCol col="8">
                    <MDBValidationItem feedback="">
                      <MDBInput
                        required
                        name="firstnameValue"
                        wrapperClass="mb-4"
                        label="Vorname"
                        id="form1"
                        type="fname"
                        value={formValue.firstnameValue}
                        onChange={onChange}
                      />
                    </MDBValidationItem>
                  </MDBCol>

                  <MDBCol col="8">
                    <MDBValidationItem feedback="">
                      <MDBInput
                        required
                        name="lastnameValue"
                        wrapperClass="mb-4"
                        label="Nachname"
                        id="form2"
                        type="lname"
                        value={formValue.lastnameValue}
                        onChange={onChange}
                      />
                    </MDBValidationItem>
                  </MDBCol>
                </MDBRow>
                <MDBValidationItem feedback="">
                  <MDBInput
                    required
                    name="emailValue"
                    wrapperClass="mb-4"
                    label="E-Mail Adresse"
                    id="form3"
                    type="email"
                    value={formValue.emailValue}
                    onChange={onChange}
                  />
                </MDBValidationItem>
                <MDBValidationItem feedback="">
                  <MDBInput
                    required
                    name="passwordValue"
                    wrapperClass="mb-4"
                    label="Passwort"
                    id="form4"
                    type="password"
                    value={formValue.passwordValue}
                    onChange={onChange}
                    className={`form-control ${
                      formValue.passwordValue
                        ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                            formValue.passwordValue
                          )
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                  />
                </MDBValidationItem>
                <MDBValidationItem feedback="">
                  <MDBInput
                    required
                    name="validationPasswordValue"
                    wrapperClass="mb-4"
                    label="Passwortbestätigung"
                    id="form4"
                    type="password"
                    value={formValue.validationPasswordValue}
                    onChange={onChange}
                    className={`form-control ${
                      formValue.passwordValue &&
                      formValue.validationPasswordValue
                        ? formValue.passwordValue ===
                          formValue.validationPasswordValue
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                  />
                </MDBValidationItem>
                <MDBValidationItem feedback="">
                  <MDBInput
                    required
                    name="telephoneValue"
                    wrapperClass="mb-4"
                    label="Telefonnummer"
                    id="form5"
                    type="tel"
                    value={formValue.telephoneValue}
                    onChange={onChange}
                  />
                </MDBValidationItem>
                <MDBValidationItem invalid feedback="">
                  <Form.Select
                    required
                    name="companyValue"
                    className="my-4"
                    aria-label="Default select example"
                    onChange={onChange}
                  >
                    <option key="blankChoice" hidden value="">
                      Unternehmen
                    </option>
                    {companies.map(({ companyid, name }) => (
                      <option key={companyid} value={companyid}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </MDBValidationItem>
                <MDBValidationItem invalid feedback="">
                  <Form.Select
                    required
                    name="departmentValue"
                    className="my-4"
                    aria-label="Default select example"
                    onChange={onChange}
                  >
                    <option key="blankChoice" hidden value="">
                      Abteilung
                    </option>
                    {departments.map(({ departmentid, abbreviation }) => (
                      <option key={departmentid} value={departmentid}>
                        {abbreviation}
                      </option>
                    ))}
                  </Form.Select>
                </MDBValidationItem>
                <MDBValidationItem className="d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey="6LdgPFkoAAAAAI2JIdWYh4kZWeiC96gkFrC-oWcL"
                    onChange={onChangeRecaptcha}
                    className="mb-4 align-items-center"
                  />
                </MDBValidationItem>
                <MDBBtn
                  type="submit"
                  color="dark"
                  className="w-100 mb-4"
                  size="sm"
                  style={{
                    backgroundColor: "#F07D00",
                    borderColor: "#F07D00",
                  }}
                >
                  Erstellen
                </MDBBtn>
              </MDBValidation>
              <p className="text-center" style={{ color: "#003C64" }}>
                Bereits registriert?{" "}
                <Link style={{ color: "#003C64" }} to="/signin">
                  Bring mich zum Login!
                </Link>
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
}
export default SignUp;
