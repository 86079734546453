import Navbar from "../Components/Navbar";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBTypography,
  MDBCardImage,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { msalLoginData } from "../auth/authCongif";

function Profile() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [telephone, setTelephone] = useState("");
  const [departmentAbrv, setDepartmentAbrv] = useState("");
  const [departmentDesc, setDepartmentDesc] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [street, setStreet] = useState("");
  const [location, setLocation] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");

  const [basicActive, setBasicActive] = useState("personal");

  const handleBasicClick = (value: string) => {
    if (value === basicActive) return;

    setBasicActive(value);
  };

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userId") == null) {
      return navigate("/signin", { replace: true });
    } else {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/api/employee/" +
          localStorage.getItem("userId")
      )
        .then((response) => response.json())
        .then((data) => {
          setFirstname(data.firstname);
          setLastname(data.lastname);
          setEmail(data.email);
          setCompany(data.Company.name);
          setTelephone(data.telephonenumber);
          setDepartmentAbrv(data.Department.abbreviation);
          setDepartmentDesc(data.Department.description);
          setHousenumber(data.Company.housenumber);
          setStreet(data.Company.street);
          setLocation(data.Company.city);
          setPostalCode(data.Company.postalcode);
          setCompanyEmail(data.Company.email);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <Container>
        <div
          className="profileMargin"
          style={{
            marginTop: "5vh",
          }}
        ></div>
        <MDBTypography
          tag="div"
          className="display-4 py-3 mt-2 mx-5 text-center headerText"
          style={{
            color: "#282D37",
          }}
        >
          Deine Profil-Visitenkarte
        </MDBTypography>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol sm="8" className="text-center mt-5 w-100">
            <MDBCard
              className="mb-3 shadow-5-strong"
              style={{ borderRadius: "2rem" }}
            >
              <MDBRow className="g-0">
                <MDBCol
                  md="3"
                  className="text-center text-white py-auto  d-flex flex-column align-items-center justify-content-center blackProfileCard"
                  style={{
                    background: "#282D37",
                    borderTopLeftRadius: "2rem",
                    borderBottomLeftRadius: "2rem",
                  }}
                >
                  <MDBCardImage
                    src="https://img.icons8.com/?size=512&id=33903&format=png"
                    alt="Avatar"
                    className="mb-3"
                    style={{ width: "120px" }}
                    fluid
                  />
                  <MDBTypography
                    tag="h5"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    {firstname} {lastname}
                  </MDBTypography>
                  <MDBCardText
                    style={{
                      color: "#ECEDE8",
                    }}
                  >
                    {departmentDesc}
                  </MDBCardText>
                </MDBCol>
                <MDBCol md="9" className="informationCard">
                  <MDBCardBody className="p-4 mx-5">
                    <MDBTypography tag="h4" className="my-3">
                      Persönliche Daten
                    </MDBTypography>
                    <hr className="mt-0 mb-5" />
                    <MDBRow
                      className={
                        "py-1 row-cols-1 row-cols-md-2 personalInformation row-cols-lg-3"
                      }
                    >
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">E-Mail</MDBTypography>
                        <MDBCardText className="text-muted">
                          {email.replace(msalLoginData.emailPrefix, "")}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">Abteilungskürzel</MDBTypography>
                        <MDBCardText className="text-muted">
                          {departmentAbrv}
                        </MDBCardText>
                      </MDBCol>
                        <MDBCol className="mb-5">
                          <MDBTypography tag="h6">Telefonnummer</MDBTypography>
                          <MDBCardText className="text-muted">
                            {telephone}
                          </MDBCardText>
                        </MDBCol>
                    </MDBRow>

                    <MDBTypography tag="h4" className="my-3">
                      Unternehmensdaten
                    </MDBTypography>
                    <hr className="mt-0 mb-5" />
                    <MDBRow className="py-1 row-cols-1 row-cols-md-2 row-cols-lg-3 companyInformation">
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">
                          Name des Unternehmens
                        </MDBTypography>
                        <MDBCardText className="text-muted">
                          {company}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">
                          E-Mail des Unternehmens
                        </MDBTypography>
                        <MDBCardText className="text-muted">
                          {companyEmail}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">Postleitzahl</MDBTypography>
                        <MDBCardText className="text-muted">
                          {postalCode}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">Straße</MDBTypography>
                        <MDBCardText className="text-muted">
                          {street}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">Hausnummer</MDBTypography>
                        <MDBCardText className="text-muted">
                          {housenumber}
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol className="mb-5">
                        <MDBTypography tag="h6">Stadt</MDBTypography>
                        <MDBCardText className="text-muted">
                          {location}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </Container>
    </>
  );
}
export default Profile;
