import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate, Link } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBTypography,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import {
  useMsal,
  useMsalAuthentication,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { msalLoginData } from "../auth/authCongif";
import { Form } from "react-bootstrap";

// AUTHENTIFICATION WITHOUT MSAL
// CREATE ACCOUNT WITHOUT MSAL
function SignIn() {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const navigate = useNavigate();
  const toggleShowAlert = () => setShowAlert(!showAlert);
  const toggleShowModal = () => setShowModal(!showModal);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setTelephonenumber("");
    setCompany("");
    setDepartment("");
    setShowModal(false);
  };
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  //REDIRECT IF ALREADY SIGNED IN
  //ELSE GET DEPARTMENTS AND COMPANIES
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      return navigate("/home", { replace: true });
    } else {
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/companies")
        .then((response) => response.json())
        .then((data) => {
          setCompanies(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/departments")
        .then((response) => response.json())
        .then((data) => {
          setDepartments(data);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  //SIGN IN WITHOUT MSAL
  function loginRequest() {
    setLoading(true);
    if (!emailValue.includes(msalLoginData.emailPrefix)) {
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: emailValue, password: passwordValue }),
      }).then((response) => {
        if (!response.ok) {
          if (!showAlert) toggleShowAlert();
        } else {
          response
            .json()
            .then((data) => localStorage.setItem("userId", data.id))
            .then(() => navigate("/home", { replace: true }));
        }
      });
    } else {
      if (!showAlert) toggleShowAlert();
    }
  }

  //MSAL AUTHENTIFICATION
  //REQUIERED INFORMATION
  const [telephonenumber, setTelephonenumber] = useState("");
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");

  //NECESSARY MSAL CONST
  const isAuthenticated = useIsAuthenticated();
  useMsalAuthentication(InteractionType.Popup);
  const { inProgress, instance, accounts } = useMsal();

  //LOGIN WITH MSAL || SILENT > POPUP > REDIRECT
  async function msalLogin() {
    setLoading(true);
    const myAccount = instance.getAccountByUsername(emailValue);
    const request = {
      scopes: ["User.Read"],
      account: myAccount ? myAccount : undefined,
    };
    await instance
      .ssoSilent(request)
      .then(() => {
        msalLoginToDatabase();
      })
      .catch(async (silentErrors) => {
        if (silentErrors) {
          await instance
            .loginPopup(request)
            .then(() => {
              msalLoginToDatabase();
            })
            .catch(async (popupErrors) => {
              if (popupErrors) {
                await instance
                  .loginRedirect(request)
                  .then(() => {
                    msalLoginToDatabase();
                  })
                  .catch((redirectErrors) => {
                    console.log(redirectErrors);
                  });
              }
            });
        }
      });
  }

  function msalLoginToDatabase() {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated) {
        fetch(
          "https://neatseat.kiebackpeter.kup:8443/neatseat/internal/employees",
          {
            method: "GET",
          }
        ).then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              if (
                data.filter(
                  (i: any) =>
                    i.email == msalLoginData.emailPrefix + accounts[0].username
                ).length > 0
              ) {
                fetch(
                  "https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signin",
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      email: msalLoginData.emailPrefix + accounts[0].username,
                      password:
                        accounts[0].idTokenClaims?.oid
                          ?.replace(/\-/g, "")
                          .substring(0, 8) + accounts[0]!.name!.split(",")[0],
                    }),
                  }
                ).then((response) => {
                  if (response.ok) {
                    response
                      .json()
                      .then((data) => localStorage.setItem("userId", data.id))
                      .then(() => navigate("/home", { replace: true }));
                  }
                });
              } else {
                handleShowModal();
              }
              setLoading(false);
            });
          }
        });
      }
    } else {
      setTimeout(() => msalLoginToDatabase(), 100);
    }
  }

  //REGISTER NEW USER BASED ON MSAL DATA
  function msalRegistration() {
    fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: msalLoginData.emailPrefix + accounts[0].username,
        lastname: accounts[0].name?.split(",")[0],
        firstname: accounts[0].name?.split(",")[1].slice(1),
        telephonenumber: telephonenumber,
        department: department,
        company: company,
        role: ["ROLE_USER"],
        password:
          accounts[0].idTokenClaims?.oid?.replace(/\-/g, "").substring(0, 8) +
          accounts[0]!.name!.split(",")[0],
      }),
    }).then((response) => {
      if (response.ok) {
        fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signin", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: msalLoginData.emailPrefix + accounts[0].username,
            password:
              accounts[0].idTokenClaims?.oid
                ?.replace(/\-/g, "")
                .substring(0, 8) + accounts[0]!.name!.split(",")[0],
          }),
        }).then((response) => {
          if (response.ok) {
            response
              .json()
              .then((data) => localStorage.setItem("userId", data.id))
              .then(() => navigate("/home", { replace: true }));
          }
        });
      }
    });
  }

  //EVENTLISTENER FOR WIDOWSIZE
  const [windowSize, setWidowSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidowSize(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  //LOADING...
  const [loading, setLoading] = useState(true);

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  return (
    <>
      <MDBContainer
        style={{
          height: "90vh",
          marginTop: "10vh",
        }}
      >
        <MDBCard
          className="signInCard"
          style={{
            height: "90%",
            borderRadius: "5rem",
            overflow: "hidden",
          }}
        >
          <MDBRow
            style={{
              height: "100%",
            }}
          >
            {windowSize > 1000 ? (
              <MDBCol
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  height: "100%",
                }}
                md={5}
              >
                <MDBCardImage
                  src="https://images.unsplash.com/photo-1587301669187-732ea66e7617?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  alt="login form"
                  className="rounded-start background-image"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </MDBCol>
            ) : (
              ""
            )}

            <MDBCol
              className="d-flex align-items-center"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "100%",
                width: windowSize > 1000 ? "" : "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              md={6}
            >
              <MDBCardBody className="d-flex flex-column justify-content-center px-0">
                <div className="d-flex flex-row mt-2 justify-content-center">
                  <img
                    style={{
                      width: windowSize > 800 ? "50%" : "80%",
                    }}
                    src="https://www.kieback-peter.com/typo3conf/ext/itools_site/Resources/Public/images/layout/KP_Logo_web.svg"
                    alt="Kieback&Peter Logo"
                    className="logo"
                  />
                </div>

                <h5
                  className="fw-normal my-4 pb-3 text-center"
                  style={{ letterSpacing: "2px", color: "#F07D00" }}
                >
                  Melden Sie sich mit Ihrem Account an.
                </h5>

                <Alert
                  show={showAlert}
                  variant="danger"
                  className="mx-5 mb-5  py-4 text-center rounded-4"
                >
                  <Alert.Heading>Anmeldung fehlgeschlagen!</Alert.Heading>
                  <p className="mb-0">
                    Ihre E-Mail Adresse und Passwort stimmen nicht überein!
                  </p>
                </Alert>

                <form onSubmit={loginRequest} className="text-center">
                  <MDBContainer
                    className="inputContainer"
                    style={{
                      width: "80%",
                      height: "100%",
                    }}
                  >
                    <MDBInput
                      wrapperClass="mb-4 emailInput"
                      label="E-Mail Adresse"
                      id="emailFormControl"
                      type="email"
                      size="lg"
                      value={emailValue}
                      onChange={(e) => setEmailValue(e.target.value)}
                    />
                    <MDBInput
                      wrapperClass="mb-4 passwordInput"
                      label="Passwort"
                      id="passwordFormControl"
                      type="password"
                      size="lg"
                      value={passwordValue}
                      onChange={(e) => setPasswordValue(e.target.value)}
                    />
                  </MDBContainer>

                  <MDBBtn
                    className="mb-4 text-center signUpBtn"
                    color="dark"
                    size="lg"
                    style={{
                      backgroundColor: "#F07D00",
                      borderColor: "#F07D00",
                      width: "80%",
                    }}
                    onClick={loginRequest}
                  >
                    Anmelden
                  </MDBBtn>
                  <MicrosoftLoginButton
                    className="mb-4 text-center msAuthBtn btn btn-lg"
                    onClick={msalLogin}
                    style={{
                      width: "80%",
                      display: "inline-block",
                      borderRadius: "0.5rem",
                    }}
                  />
                </form>
                <Link
                  className="small text-center"
                  style={{ color: "#003C64" }}
                  to="/resetEmail"
                >
                  Passwort vergessen?
                </Link>
                <p
                  className="mb-5 pb-lg-2 text-center"
                  style={{ color: "#F07D00" }}
                >
                  Keinen Account?{" "}
                  <Link style={{ color: "#F07D00" }} to="/signup">
                    Dann erstellen Sie einen!{" "}
                  </Link>
                </p>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
        <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  Benötigte Registrierungsinformationen
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={handleCloseModal}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBValidation onSubmit={msalRegistration}>
                <MDBModalBody>
                  <MDBValidationItem className="mx-4 my-2" feedback="">
                    <MDBInput
                      required
                      name="telephoneValue"
                      wrapperClass="mb-4"
                      label="Telefonnummer"
                      id="form5"
                      type="tel"
                      value={telephonenumber}
                      onChange={(e) => setTelephonenumber(e.target.value)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem className="mx-4 my-2" invalid feedback="">
                    <Form.Select
                      required
                      name="companyValue"
                      className="my-4"
                      aria-label="Default select example"
                      onChange={(e) => setCompany(e.target.value)}
                    >
                      <option key="blankChoice" hidden value="">
                        Unternehmen
                      </option>
                      {companies.map(({ companyid, name }) => (
                        <option key={companyid} value={companyid}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </MDBValidationItem>
                  <MDBValidationItem className="mx-4 my-2" invalid feedback="">
                    <Form.Select
                      required
                      name="departmentValue"
                      className="my-4"
                      aria-label="Default select example"
                      onChange={(e) => setDepartment(e.target.value)}
                    >
                      <option key="blankChoice" hidden value="">
                        Abteilung
                      </option>
                      {departments.map(({ departmentid, abbreviation }) => (
                        <option key={departmentid} value={departmentid}>
                          {abbreviation}
                        </option>
                      ))}
                    </Form.Select>
                  </MDBValidationItem>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={handleCloseModal}>
                    Abbrechen
                  </MDBBtn>
                  <MDBBtn color="success" type="submit">
                    Registrieren
                  </MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </>
  );
}

export default SignIn;
