import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Homepage from "../src/Views/Homepage";
import Profile from "../src/Views/Profile";
import Reserve from "../src/Views/Reserve";
import Bookings from "../src/Views/Bookings";
import SignUp from "../src/Views/SignUp";
import SignIn from "../src/Views/SignIn";
import Overview from "../src/Views/Overview";
import ResetPassword from "../src/Views/ResetPassword";
import ResetPasswordEmailConfirmation from "../src/Views/ResetPasswordEmailConfirmation";
import "./App.css";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="" element={<Homepage />} />
        <Route path="/index.html" element={<Navigate to="/" />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reserve" element={<Reserve />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/resetEmail" element={<ResetPasswordEmailConfirmation />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
