import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavbarBS from "react-bootstrap/Navbar";
import Typewriter from 'typewriter-effect';
import {
  MDBSwitch,
  MDBTypography,
  MDBIcon,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBContainer
} from "mdb-react-ui-kit";

function Navbar() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("userId") == null) {
      return navigate("/signin", { replace: true });
    } else {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/api/employee/" +
          localStorage.getItem("userId")
      )
        .then((response) => response.json())
        .then((data) => {
          setName(data.firstname + " " + data.lastname);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  });
  function logoutRequest() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    fetch(
      "https://neatseat.kiebackpeter.kup:8443/neatseat/auth/signout",
      requestOptions
    ).then((response) => {
      if (response.ok) {
        localStorage.removeItem("userId");
        return navigate("/signin", { replace: true });
      }
    });
  }
  
  return (
    <NavbarBS
      collapseOnSelect
      expand="lg"
      sticky="top"
      bg="light"
      className="py-0 w-100"
    >
      <MDBContainer fluid
        style={{
          marginLeft: "10%",
          marginRight:"10%",
        }}
      >
        <NavbarBS.Brand href="#/home">
          <img
            src="https://ebt-ib.de/wp-content/uploads/2020/09/KP_Logo_rgb-1024x181.png"
            height="35"
            className="d-inline-block align-top my-3"
            alt="Kieback&Peter Logo"
          />
        </NavbarBS.Brand>
        <NavbarBS.Toggle aria-controls="basic-navbar-nav">
          <MDBIcon fas icon="align-justify" />
        </NavbarBS.Toggle>

        <NavbarBS.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mx-2">
            <Nav.Item>
              <Nav.Link
                className="mx-2"
                href="#/home"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="mx-2"
                href="#/reserve"
              >
                Reservieren
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="mx-2"
                href="#/bookings"
              >
                Buchungen
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="justify-content-end">
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role="button">
                  Angemeldet als:
                  <MDBTypography tag="strong" className="mx-2 typewriter">
                    {name}
                  </MDBTypography>
                </MDBDropdownToggle>
                <MDBDropdownMenu responsive='lg-end'>
                  <MDBDropdownItem header>Was möchten Sie tun?</MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    className="text-center"
                    href="#/profile"
                  >
                    Profil anzeigen
                  </MDBDropdownItem>
                  <MDBDropdownItem divider />
                  <MDBDropdownItem
                    link
                    className="text-center"
                    onClick={() => logoutRequest()}
                  >
                    Abmelden
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </Nav>
        </NavbarBS.Collapse>
      </MDBContainer>
    </NavbarBS>
  );
}

export default Navbar;
