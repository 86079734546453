import { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Container from "react-bootstrap/Container";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTypography,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import de from "@fullcalendar/core/locales/de";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

function Bookings() {
  const [show, setShow] = useState(false);
  interface calendarEvent {
    id: string;
    title: string;
    start: string;
    end: string;
    color: string;
  }
  interface bookingObj {
    id: string;
    bookingdate: string;
    begindate: string;
    enddate: string;
    Employee: {
      employeeid: string;
      firstname: string;
      lastname: string;
      email: string;
      telephonenumber: string;
      begindate: string;
      enddate: string;
      status: Boolean;
    };
    Workplace: {
      workplaceid: string;
      description: string;
      begindate: string;
      enddate: string;
      status: Boolean;
    };
  }
  interface deviceObj {
    deviceid: string;
    manufacturer: string;
    begindate: string;
    enddate: string;
    status: Boolean;
    DeviceWorkplace: [
      {
        deviceWorkplacePK: {
          fkDeviceid: string;
          fkWorkplaceid: string;
        };
        assignmentdate: string;
        serialnumber: string;
        workplace: {
          workplaceid: string;
          description: string;
          begindate: string;
          enddate: string;
          status: Boolean;
        };
      }
    ];
    DeviceType: {
      devicetypeid: string;
      description: string;
    };
  }
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (event: any) => {
    let devicesOfBooking = Array();
    for (const booking of bookings) {
      if (event.event.id === booking.id) {
        setSelectedBooking(booking);
        setDefaultViewDate(new Date(booking.begindate))
        devices.forEach((device) => {
          device.DeviceWorkplace.forEach((workplace) => {
            if (
              workplace.workplace.workplaceid === booking.Workplace.workplaceid
            ) {
              devicesOfBooking.push(device);
              return;
            }
          });
        });
        setSelectedBookingDevices(devicesOfBooking);
        break;
      }
    }
    setShow(true);
  };
  const navigate = useNavigate();
  const [bookings, setBookings] = useState<bookingObj[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<calendarEvent[]>([]);
  const [selectedBooking, setSelectedBooking] = useState<bookingObj>();
  const [devices, setDevices] = useState<deviceObj[]>([]);
  const [selectedBookingDevices, setSelectedBookingDevices] = useState<
    deviceObj[]
  >([]);
  const [defaultViewDate, setDefaultViewDate] = useState<Date>(new Date());

  useEffect(() => {
    if (localStorage.getItem("userId") == null) {
      return navigate("/signin", { replace: true });
    } else {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/api/employee/" +
          localStorage.getItem("userId")
      )
        .then((response) => response.json())
        .then((data) => {
          let bookingsAsCalendarEvents = Array();
          data.Booking.forEach((booking: any) => {
            bookingsAsCalendarEvents.push({
              id: booking.id,
              title: booking.Workplace.description,
              start:
                new Date(booking.begindate).toJSON().substring(0, 19) + "Z",
              end: new Date(booking.enddate).toJSON().substring(0, 19) + "Z",
              color: "#00568F",
            });
          });
          setBookings(data.Booking);
          setCalendarEvents(bookingsAsCalendarEvents);
        })
        .catch((err) => {
          console.log(err.message);
        });
      fetch("https://neatseat.kiebackpeter.kup:8443/neatseat/api/devices/")
        .then((response) => response.json())
        .then((data) => {
          setDevices(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const Calendar = () => {
    return (
      <div className="mt-5">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            bootstrapPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
            momentTimezonePlugin,
          ]}
          initialView="timeGridWeek"
          headerToolbar={{
            start: "prev",
            center: "title",
            right: "next",
          }}
          locale={de}
          timeZone="Europe/Berlin"
          editable={false}
          expandRows={false}
          buttonText={{
            prev: "Vorherige Woche",
            next: "Nächste Woche",
          }}
          allDaySlot={false}
          slotLabelFormat={{
            hour: "numeric",
            meridiem: false,
          }}
          initialDate={defaultViewDate}
          themeSystem="bootstrap5"
          weekends={false}
          slotMinTime="05:00:00"
          slotMaxTime="20:00:00"
          nowIndicator={true}
          events={calendarEvents}
          eventClick={(e) => handleShow(e)}
        />
      </div>
    );
  };

  const deleteBooking = () => {
    if (selectedBooking?new Date(selectedBooking.enddate) > new Date():false) {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/api/booking/" +
          selectedBooking?.id,
        {
          method: "DELETE",
        }
      ).then((response) => {
        if (response.ok) {
          handleClose();
          let updateCalendarEventsAfterDelete = Array();
          updateCalendarEventsAfterDelete = calendarEvents;
          setCalendarEvents(
            updateCalendarEventsAfterDelete.filter(
              (calendarEvent) => calendarEvent.id != selectedBooking?.id
            )
          );
        }
      }).catch((err) => {
        console.log(err.message);
      });;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <Container>
        <MDBTypography
          tag="div"
          className="display-5 py-3 mt-2 mx-5 border-bottom text-center"
          style={{
            color: "#003C64",
          }}
        >
          Buchungsübersicht
        </MDBTypography>
        <Calendar />
        <MDBModal show={show} setShow={setShow} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Buchungsinformationen</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={handleClose}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                  <div className="text-center">
                    <MDBTypography tag="h3">
                      {selectedBooking?.Workplace.description}
                    </MDBTypography>
                    <p className="text-muted mb-4">
                      Vom{" "}
                      {selectedBooking
                        ? new Date(
                            selectedBooking.begindate
                          ).toLocaleDateString([], {day: '2-digit', month:'2-digit', year:'2-digit'}) +
                          " um " +
                          new Date(
                            selectedBooking.begindate
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) +
                          " Uhr."
                        : ""}
                      <br />
                      Bis zum{" "}
                      {selectedBooking
                        ? new Date(
                            selectedBooking.enddate
                          ).toLocaleDateString([], {day: '2-digit', month:'2-digit', year:'2-digit'}) +
                          " um " +
                          new Date(
                            selectedBooking.enddate
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) +
                          " Uhr."
                        : ""}
                    </p>
                  </div>
                  <br />
                  {selectedBookingDevices.map((device: deviceObj, index) => {
                    return (
                        <div key={index} className="d-flex justify-content-between">
                          <span className="mx-5">
                            {device.DeviceType.description}
                          </span>
                          <span className="mx-5">{device.manufacturer}</span>
                        </div>
                    );
                  })}
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={handleClose}>
                  Schließen
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  onClick={deleteBooking}
                  disabled={
                    selectedBooking
                      ? new Date(selectedBooking.enddate) < new Date()
                      : false
                  }
                >
                  Löschen
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </Container>
    </>
  );
}
export default Bookings;
