import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate, Link } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";

function ResetPasswordEmailConfirmation() {
  const [emailValue, setEmailValue] = useState("");
  const navigate = useNavigate();
  const toggleShow = () => setShow(!show);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState();

  function resetPasswordRequest() {
    setLoading(true);
    fetch(
      "https://neatseat.kiebackpeter.kup:8443/neatseat/auth/resetPassword?email=" + emailValue,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        if (response.ok) {
          if (!show) toggleShow();
        } else {
        }
      })
      .then(() => setLoading(false));
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      return navigate("/home", { replace: true });
    } else {
      setLoading(false);
    }
  }, []);
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  return (
    <>
      <MDBContainer className="my-5">
        <MDBCard className="resetCard">
          <MDBRow>
            <MDBCol
              md="6"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <MDBCardImage
                src="https://images.unsplash.com/photo-1587301669187-732ea66e7617?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                alt="login form"
                className="rounded-start background-image"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </MDBCol>

            <MDBCol
              className="d-flex align-items-center"
              md="6"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <MDBCardBody className="d-flex flex-column ">
                <div className="d-flex flex-row mt-2 justify-content-center">
                  <img
                    height="80vh"
                    src="https://www.kieback-peter.com/typo3conf/ext/itools_site/Resources/Public/images/layout/KP_Logo_web.svg"
                    alt="Kieback&Peter Logo"
                    className="logo"
                  />
                </div>

                <h5
                  className="fw-normal my-4 pb-3 text-center"
                  style={{ letterSpacing: "2px", color: "#F07D00" }}
                >
                  E-Mail zum Zurücksetzen deines Passworts
                </h5>
                <Alert
                  show={show}
                  variant="success"
                  className="mx-5 mb-5  py-4 text-center rounded-4"
                >
                  <Alert.Heading>E-Mail versendet!</Alert.Heading>
                  <p className="mb-0">
                    Ein Link zum Zurücksetzen deines Passworts wurde an deine
                    E-Mail Adresse geschickt.
                  </p>
                </Alert>

                <MDBContainer
                  className="inputContainer"
                  style={{
                    width: "80%",
                    height: "100%",
                  }}
                >
                  <MDBValidation>
                    <MDBValidationItem feedback="">
                      <MDBInput
                        wrapperClass="mb-4 emailInput"
                        label="E-Mail Adresse"
                        id="formControlLg"
                        type="email"
                        size="lg"
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                      />
                    </MDBValidationItem>
                  </MDBValidation>
                </MDBContainer>

                <MDBBtn
                  className="mb-4 px-5 text-center mx-5 signUpBtn"
                  color="dark"
                  size="lg"
                  style={{ backgroundColor: "#F07D00", borderColor: "#F07D00" }}
                  onClick={() => resetPasswordRequest()}
                >
                  Link zum Zurücksetzen erhalten
                </MDBBtn>
                <Link
                  className="small text-center"
                  style={{ color: "#F07D00" }}
                  to="/signin"
                >
                  Zurück zur Anmeldung
                </Link>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </>
  );
}

export default ResetPasswordEmailConfirmation;
