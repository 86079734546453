import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";

function ResetPassword() {
  const [queryParameters] = useSearchParams();
  const [reenterPasswordValue, setReenterPasswordValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const navigate = useNavigate();
  const toggleShow = () => setShow(!show);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState();

  function resetPasswordRequest() {
    setLoading(true);
    fetch(
      "https://neatseat.kiebackpeter.kup:8443/neatseat/auth/processPassword?token=" +
        queryParameters.get("token"),
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: passwordValue,
        }),
      }
    ).then((response) => {
      if (response.ok) {
        if (!show) {
          toggleShow();
          setLoading(false);
          setTimeout(() =>{return navigate("/signin", { replace: true });},3000)
          setLoading(true);
        }
      } else {
        setLoading(false);
      }
    });
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      return navigate("/home", { replace: true });
    } else {
      fetch(
        "https://neatseat.kiebackpeter.kup:8443/neatseat/auth/changePassword?token=" +
          queryParameters.get("token")
      ).then((response) => {
        if (response.ok) {
          setLoading(false);
        } else {
          return navigate("/reset", { replace: true });
        }
      });
      setLoading(false);
    }
  });
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBSpinner
          color="primary"
          style={{
            width: "5rem",
            height: "5rem",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  return (
    <>
      <MDBContainer className="my-5">
        <MDBCard className="resetCard">
          <MDBRow>
            <MDBCol
              md="6"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <MDBCardImage
                src="https://images.unsplash.com/photo-1587301669187-732ea66e7617?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                alt="login form"
                className="rounded-start background-image"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </MDBCol>

            <MDBCol
              className="d-flex align-items-center"
              md="6"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <MDBCardBody className="d-flex flex-column ">
                <div className="d-flex flex-row mt-2 justify-content-center">
                  <img
                    height="80vh"
                    src="https://www.kieback-peter.com/typo3conf/ext/itools_site/Resources/Public/images/layout/KP_Logo_web.svg"
                    alt="Kieback&Peter Logo"
                    className="logo"
                  />
                </div>

                <h5
                  className="fw-normal my-4 pb-3 text-center"
                  style={{ letterSpacing: "2px", color: "#F07D00" }}
                >
                  Setzen Sie Ihr Passwort zurück.
                </h5>

                <Alert show={show} variant="success" className="mx-5">
                  <Alert.Heading>Password geändert!</Alert.Heading>
                  <p>Dein Passwort wurde erfolgreich abgeändert.</p>
                </Alert>

                <MDBContainer
                  className="inputContainer"
                  style={{
                    width: "80%",
                    height: "100%",
                  }}
                >
                  <MDBValidation>
                    <MDBValidationItem feedback="">
                      <MDBInput
                        wrapperClass="mb-4 passwordInput"
                        label="Neues Passwort"
                        id="password"
                        type="password"
                        size="lg"
                        value={passwordValue}
                        onChange={(e) => setPasswordValue(e.target.value)}
                        className={`form-control ${
                          reenterPasswordValue && passwordValue
                            ? passwordValue === reenterPasswordValue
                              ? "is-valid"
                              : "is-invalid"
                            : ""
                        }`}
                      />
                    </MDBValidationItem>

                    <MDBValidationItem feedback="">
                      <MDBInput
                        wrapperClass="mb-4 passwordInput"
                        label="Passwortbestätigung"
                        id="reenteredPassword"
                        type="password"
                        size="lg"
                        value={reenterPasswordValue}
                        onChange={(e) =>
                          setReenterPasswordValue(e.target.value)
                        }
                        className={`form-control ${
                          reenterPasswordValue && passwordValue
                            ? passwordValue === reenterPasswordValue
                              ? "is-valid"
                              : "is-invalid"
                            : ""
                        }`}
                      />
                    </MDBValidationItem>
                  </MDBValidation>
                </MDBContainer>

                <MDBBtn
                  className="mb-4 px-5 text-center mx-5 signUpBtn"
                  color="dark"
                  size="lg"
                  style={{ backgroundColor: "#F07D00", borderColor: "#F07D00" }}
                  onClick={() => resetPasswordRequest()}
                >
                  Passwort ändern
                </MDBBtn>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </>
  );
}

export default ResetPassword;
